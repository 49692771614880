.container{
    top: 0;
    bottom: 0;
    position: absolute;
    left: 0;
    right: 0;
    background-color: #f2f2f2;
    z-index: 2;
}

.image {
    width: 80%;
}

@media (max-width: 480px) {
    .image {
        width: 21%;
    }

}

.thisButton {
    background-color: #2096F3;
    border-radius: 8px;
    direction: ltr;
    text-align: center;
    font-size: 0.8rem;
    padding: 1.3vh 0vw;
    color: #fff;
    width: 25%;
}
