.container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.header{
    /*background-color: #D3D3D3B0;*//*background-color: #D3D3D3B0;*/
    height: 100%;
    z-index: 10;
    top: 0;
    color: #fff;
}

.frame {
    top: 0;
    bottom: 0;
    background-repeat: repeat;
    background-position: center center;
    background-size: cover;
}

.thisButton {



    background-color: #2096F3;
    border-radius: 8px;
    direction: ltr;
    text-align: center;
    font-size: 0.8rem;
    padding: 1.3vh 0vw;
    color: #fff;
    width: 30%;
    bottom: 5%;
    z-index: 11;

}
