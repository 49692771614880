* {
  margin: 0;
  padding: 0;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.user-select * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

*:focus {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

html, body {
  height: 100%;
  width: 100%;
  font-family: iranyekan, serif;
  font-size: 0.8vw;
  line-height: 3.5vh;
  direction: rtl;
  background-color: #f2f5f9;
  color: #030303;
}

@media (max-width: 480px) {
  html, body {
    font-size: 3.3vw;
    line-height: 3.5vh;
  }
}

a {
  color: #030303;
  text-decoration: none;

  /*color: #282c34;*/
}

@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: normal;
  src: url("assets/fonts/IRANYekan/Farsi_numerals/eot/iranyekanwebregularfanum.eot");
  src: url("assets/fonts/IRANYekan/Farsi_numerals/eot/iranyekanwebregularfanum.eot?#iefix") format("e "),
    /* IE6-8 */ url("assets/fonts/IRANYekan/Farsi_numerals/woff/iranyekanwebregularfanum.woff") format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/ url("assets/fonts/IRANYekan/Farsi_numerals/ttf/iranyekanwebregularfanum.ttf") format("truetype");
}

.container-bg {
  background-color: #fff;
  box-shadow: 0px 9px 16px rgb(159 162 191 / 18%), 0px 2px 2px rgb(159 162 191 / 32%);
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.row-reverse {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.jc-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.jc-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.jc-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.jc-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.jc-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.ai-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start !important;
}

.ai-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ai-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end !important;
}

.ai-baseline {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}

.flex-wrap {
  flex-wrap: wrap;
}


.text-red {
  color: #d73e36;
}

.text-green {
  color: #18a979;
}

.text-blue {
  color: #3857d8;
}
.text-light-blue {
  color: #2096F3;
}

.text-orange {
  color: #ef7909;
}

.text-purple {
  color: #990fe1;
}

.text-gray {
  color: #4c4c4cd9;
}

.text-white {
  color: #fff;
}

.text-start {
  text-align: start;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.text-right {
  text-align: right;
}

.ltr .text-right {
  text-align: left;
}

.text-left {
  text-align: left;
}

.ltr .text-left {
  text-align: right;
}


.position-absolute {
  position: absolute;
}

.position-relative {
  position: relative;
}

.visibility-hidden {
  visibility: hidden;
}

.hover-scale-01:hover {
  font-size: 1.1rem;
}

.hover-text:hover {
  /* color: var(--activeTab);*/
}

.hover-underline:hover {
  text-decoration: underline;
}

.visibility-hidden {
  visibility: hidden;
}


.mb-025 {
  margin-bottom: 0.25vh;
}

.mb-05 {
  margin-bottom: 0.5vh;
}

.mb-1 {
  margin-bottom: 1vh;
}

.mb-2 {
  margin-bottom: 2vh;
}

.mb-3 {
  margin-bottom: 3vh;
}

.mb-4 {
  margin-bottom: 4vh;
}

.mb-5 {
  margin-bottom: 5vh;
}

.mb-6 {
  margin-bottom: 6vh;
}

.mb-7 {
  margin-bottom: 7vh;
}

.mb-8 {
  margin-bottom: 8vh;
}

.mb-9 {
  margin-bottom: 9vh;
}

.mb-10 {
  margin-bottom: 10vh;
}

.mb-15 {
  margin-bottom: 15vh;
}

.mb-20 {
  margin-bottom: 20vh;
}

.mt-025 {
  margin-top: 0.25vh;
}

.mt-05 {
  margin-top: 0.5vh;
}

.mt-1 {
  margin-top: 1vh;
}

.mt-2 {
  margin-top: 2vh;
}

.mt-3 {
  margin-top: 3vh;
}

.mt-4 {
  margin-top: 4vh;
}

.mt-5 {
  margin-top: 5vh;
}

.mt-6 {
  margin-top: 6vh;
}

.mt-7 {
  margin-top: 7vh;
}

.mt-8 {
  margin-top: 8vh;
}

.mt-9 {
  margin-top: 9vh;
}

.mt-10 {
  margin-top: 10vh;
}

.mt-15 {
  margin-top: 15vh;
}

.mt-20 {
  margin-top: 20vh;
}

.mr-025 {
  margin-right: 0.25vw;
}

.ltr .mr-025 {
  margin-left: 0.25vw;
  margin-right: initial;
}

.mr-05 {
  margin-right: 0.5vw;
}

.ltr .mr-05 {
  margin-left: 0.5vw;
  margin-right: initial;
}

.mr-1 {
  margin-right: 1vw;
}

.ltr .mr-1 {
  margin-left: 1vw;
  margin-right: initial;
}

.mr-2 {
  margin-right: 2vw;
}

.ltr .mr-2 {
  margin-left: 2vw;
  margin-right: initial;
}

.mr-3 {
  margin-right: 3vw;
}

.ltr .mr-2 {
  margin-left: 3vw;
  margin-right: initial;
}

.mr-4 {
  margin-right: 4vw;
}

.ltr .mr-4 {
  margin-left: 4vw;
  margin-right: initial;
}

.mr-5 {
  margin-right: 5vw;
}

.ltr .mr-5 {
  margin-left: 5vw;
  margin-right: initial;
}

.mr-10 {
  margin-right: 10vw;
}

.ltr .mr-10 {
  margin-left: 10vw;
  margin-right: initial;
}


.ml-025 {
  margin-left: 0.25vw;
}

.ltr .ml-025 {
  margin-right: 0.25vw;
  margin-left: initial;
}

.ml-05 {
  margin-left: 0.5vw;
}

.ltr .ml-05 {
  margin-right: 0.5vw;
  margin-left: initial;
}

.ml-1 {
  margin-left: 1vw;
}

.ltr .ml-1 {
  margin-right: 1vw;
  margin-left: initial;
}

.ml-2 {
  margin-left: 2vw;
}

.ltr .ml-2 {
  margin-right: 2vw;
  margin-left: initial;
}

.ml-3 {
  margin-left: 3vw;
}

.ltr .ml-3 {
  margin-right: 3vw;
  margin-left: initial;
}

.ml-4 {
  margin-left: 4vw;
}

.ltr .ml-4 {
  margin-right: 4vw;
  margin-left: initial;
}

.ml-5 {
  margin-left: 5vw;
}

.ltr .ml-5 {
  margin-right: 5vw;
  margin-left: initial;
}

.ml-10 {
  margin-left: 10vw;
}

.ltr .ml-10 {
  margin-right: 10vw;
  margin-left: initial;
}

.mx-025 {
  margin-right: 0.25vw;
  margin-left: 0.25vw;
}

.mx-05 {
  margin-right: 0.5vw;
  margin-left: 0.5vw;
}

.mx-1 {
  margin-right: 1vw;
  margin-left: 1vw;
}

.mx-2 {
  margin-right: 2vw;
  margin-left: 2vw;
}

.mx-3 {
  margin-right: 3vw;
  margin-left: 3vw;
}

.mx-4 {
  margin-right: 4vw;
  margin-left: 4vw;
}

.mx-5 {
  margin-right: 5vw;
  margin-left: 5vw;
}

.mx-6 {
  margin-right: 6vw;
  margin-left: 6vw;
}

.mx-7 {
  margin-right: 7vw;
  margin-left: 7vw;
}

.mx-8 {
  margin-right: 8vw;
  margin-left: 8vw;
}

.mx-9 {
  margin-right: 9vw;
  margin-left: 9vw;
}

.mx-10 {
  margin-right: 10vw;
  margin-left: 10vw;
}

.my-025 {
  margin-top: 0.25vh;
  margin-bottom: 0.25vh;
}

.my-05 {
  margin-top: 0.5vh;
  margin-bottom: 0.5vh;
}

.my-1 {
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.my-2 {
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.my-3 {
  margin-top: 3vh;
  margin-bottom: 3vh;
}

.my-4 {
  margin-top: 4vh;
  margin-bottom: 4vh;
}

.my-5 {
  margin-top: 5vh;
  margin-bottom: 5vh;
}

.my-6 {
  margin-top: 6vh;
  margin-bottom: 6vh;
}

.my-7 {
  margin-top: 7vh;
  margin-bottom: 7vh;
}

.my-8 {
  margin-top: 8vh;
  margin-bottom: 8vh;
}

.my-9 {
  margin-top: 9vh;
  margin-bottom: 9vh;
}

.my-10 {
  margin-top: 10vh;
  margin-bottom: 10vh;
}

.m-auto {
  margin: 0 auto;
}


.pb-025 {
  padding-bottom: 0.25vh;
}

.pb-05 {
  padding-bottom: 0.5vh;
}

.pb-1 {
  padding-bottom: 1vh;
}

.pb-2 {
  padding-bottom: 2vh;
}

.pb-3 {
  padding-bottom: 3vh;
}

.pb-4 {
  padding-bottom: 3vh;
}

.pb-5 {
  padding-bottom: 5vh;
}

.pb-6 {
  padding-bottom: 6vh;
}

.pb-7 {
  padding-bottom: 7vh;
}

.pb-8 {
  padding-bottom: 8vh;
}

.pb-9 {
  padding-bottom: 9vh;
}

.pb-10 {
  padding-bottom: 10vh;
}

.pb-15 {
  padding-bottom: 15vh;
}

.pb-20 {
  padding-bottom: 20vh;
}

.pt-025 {
  padding-top: 0.25vh;
}

.pt-05 {
  padding-top: 0.5vh;
}

.pt-1 {
  padding-top: 1vh;
}

.pt-2 {
  padding-top: 2vh;
}

.pt-3 {
  padding-top: 3vh;
}

.pt-4 {
  padding-top: 4vh;
}

.pt-5 {
  padding-top: 5vh;
}

.pt-6 {
  padding-top: 6vh;
}

.pt-7 {
  padding-top: 7vh;
}

.pt-8 {
  padding-top: 8vh;
}

.pt-9 {
  padding-top: 9vh;
}

.pt-10 {
  padding-top: 10vh;
}

.pt-15 {
  padding-top: 15vh;
}

.pt-20 {
  padding-top: 20vh;
}

.pr-025 {
  padding-right: 0.25vw;
}

.ltr .pr-025 {
  padding-left: 0.25vw;
  padding-right: initial;
}

.pr-05 {
  padding-right: 0.5vw;
}

.ltr .pr-05 {
  padding-left: 0.5vw;
  padding-right: initial;
}

.pr-1 {
  padding-right: 1vw;
}

.ltr .pr-1 {
  padding-left: 1vw;
  padding-right: initial;
}

.pr-2 {
  padding-right: 2vw;
}

.ltr .pr-2 {
  padding-left: 2vw;
  padding-right: initial;
}

.pr-3 {
  padding-right: 3vw;
}

.ltr .pr-3 {
  padding-left: 3vw;
  padding-right: initial;
}

.pr-4 {
  padding-right: 4vw;
}

.ltr .pr-4 {
  padding-left: 4vw;
  padding-right: initial;
}

.pr-5 {
  padding-right: 5vw;
}

.ltr .pr-5 {
  padding-left: 5vw;
  padding-right: initial;
}

.pr-10 {
  padding-right: 10vw;
}

.ltr .pr-10 {
  padding-left: 10vw;
  padding-right: initial;
}

.pl-025 {
  padding-left: 0.25vw;
}

.ltr .pl-05 {
  padding-right: 0.25vw;
  padding-left: initial;
}

.pl-05 {
  padding-left: 0.5vw;
}

.ltr .pl-05 {
  padding-right: 0.5vw;
  padding-left: initial;
}

.pl-1 {
  padding-left: 1vw;
}

.ltr .pl-1 {
  padding-right: 1vw;
  padding-left: initial;
}

.pl-2 {
  padding-left: 2vw;
}

.ltr .pl-2 {
  padding-right: 2vw;
  padding-left: initial;
}

.pl-3 {
  padding-left: 3vw;
}

.ltr .pl-3 {
  padding-right: 3vw;
  padding-left: initial;
}

.pl-4 {
  padding-left: 4vw;
}

.ltr .pl-4 {
  padding-right: 4vw;
  padding-left: initial;
}

.pl-5 {
  padding-left: 5vw;
}

.ltr .pl-5 {
  padding-right: 5vw;
  padding-left: initial;
}

.pl-10 {
  padding-left: 10vw;
}

.ltr .pl-10 {
  padding-right: 10vw;
  padding-left: initial;
}

.px-025 {
  padding-right: 0.25vw;
  padding-left: 0.25vw;
}

.px-05 {
  padding-right: 0.5vw;
  padding-left: 0.5vw;
}

.px-1 {
  padding-right: 1vw;
  padding-left: 1vw;
}

.px-2 {
  padding-right: 2vw;
  padding-left: 2vw;
}

.px-3 {
  padding-right: 3vw;
  padding-left: 3vw;
}

.px-4 {
  padding-right: 4vw;
  padding-left: 4vw;
}

.px-5 {
  padding-right: 5vw;
  padding-left: 5vw;
}

.px-6 {
  padding-right: 6vw;
  padding-left: 6vw;
}

.px-7 {
  padding-right: 7vw;
  padding-left: 7vw;
}

.px-8 {
  padding-right: 8vw;
  padding-left: 8vw;
}

.px-9 {
  padding-right: 9vw;
  padding-left: 9vw;
}

.px-10 {
  padding-right: 10vw;
  padding-left: 10vw;
}

.py-025 {
  padding-top: 0.25vh;
  padding-bottom: 0.25vh;
}

.py-05 {
  padding-top: 0.5vh;
  padding-bottom: 0.5vh;
}

.py-1 {
  padding-top: 1vh;
  padding-bottom: 1vh;
}

.py-105 {
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
}

.py-2 {
  padding-top: 2vh;
  padding-bottom: 2vh;
}

.py-3 {
  padding-top: 3vh;
  padding-bottom: 3vh;
}

.py-4 {
  padding-top: 4vh;
  padding-bottom: 4vh;
}

.py-5 {
  padding-top: 5vh;
  padding-bottom: 5vh;
}

.py-6 {
  padding-top: 6vh;
  padding-bottom: 6vh;
}

.py-7 {
  padding-top: 7vh;
  padding-bottom: 7vh;
}

.py-8 {
  padding-top: 8vh;
  padding-bottom: 8vh;
}

.py-9 {
  padding-top: 9vh;
  padding-bottom: 9vh;
}

.py-10 {
  padding-top: 10vh;
  padding-bottom: 10vh;
}


.rounded-1 {
  border-radius: 1px;
}

.rounded-2 {
  border-radius: 2px;
}

.rounded-3 {
  border-radius: 3px;
}

.rounded-4 {
  border-radius: 4px;
}

.rounded-5 {
  border-radius: 5px;
}

.rounded-6 {
  border-radius: 6px;
}

.rounded-7 {
  border-radius: 7px;
}

.rounded-8 {
  border-radius: 8px;
}

.rounded-9 {
  border-radius: 9px;
}

.rounded-10 {
  border-radius: 10px;
}

.rounded-11 {
  border-radius: 11px;
}

.rounded-12 {
  border-radius: 12px;
}

.rounded-13 {
  border-radius: 13px;
}

.rounded-14 {
  border-radius: 14px;
}

.rounded-15 {
  border-radius: 15px;
}

.rounded-20 {
  border-radius: 20px;
}

.rounded-50-p {
  border-radius: 50%;
}

.rounded-100-p {
  border-radius: 100%;
}


li {
  list-style: none;
}


.direction-ltr {
  direction: ltr !important;
}

.direction-rtl {
  direction: rtl !important;
}

.break-word {
  word-break: break-word;
}

.font-weight-bold {
  font-weight: bold;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-400 {
  font-weight: 400;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-grabbing {
  cursor: grabbing;
}
.cursor-copy {
  cursor: copy;
}

.fs-0-5 {
  font-size: 0.5rem;
}

.fs-0-6 {
  font-size: 0.6rem;
}

.fs-0-7 {
  font-size: 0.7rem;
}

.fs-0-8 {
  font-size: 0.8rem;
}

.fs-0-9 {
  font-size: 0.9rem;
}

.fs-01 {
  font-size: 1.1rem;
}

.fs-02 {
  font-size: 1.2rem;
}

.fs-03 {
  font-size: 1.31rem;
}

.fs-04 {
  font-size: 1.4rem;
}

.fs-05 {
  font-size: 1.5rem;
}

.fs-06 {
  font-size: 1.6rem;
}

.fs-07 {
  font-size: 1.7rem;
}

.fs-08 {
  font-size: 1.48rem;
}

.fs-09 {
  font-size: 1.9rem;
}

.fs-20 {
  font-size: 2rem;
}

.fs-21 {
  font-size: 2.1rem;
}

.fs-22 {
  font-size: 2.2rem;
}
.fs-30 {
  font-size: 3rem;
}

.fs-50 {
  font-size: 5rem;
}


.width-1 {
  width: 1%;
}

.width-2 {
  width: 2%;
}

.width-3 {
  width: 3%;
}

.width-4 {
  width: 4%;
}

.width-5 {
  width: 5%;
}

.width-6 {
  width: 6%;
}

.width-7 {
  width: 7%;
}

.width-8 {
  width: 8%;
}

.width-9 {
  width: 9%;
}

.width-10 {
  width: 10%;
}

.width-11 {
  width: 11%;
}

.width-12 {
  width: 12%;
}

.width-13 {
  width: 13%;
}

.width-14 {
  width: 14%;
}

.width-15 {
  width: 15%;
}

.width-16 {
  width: 16%;
}

.width-17 {
  width: 17%;
}

.width-18 {
  width: 18%;
}

.width-19 {
  width: 19%;
}

.width-20 {
  width: 20%;
}

.width-21 {
  width: 21%;
}

.width-22 {
  width: 22%;
}

.width-23 {
  width: 23%;
}

.width-24 {
  width: 24%;
}

.width-25 {
  width: 25%;
}

.width-26 {
  width: 26%;
}

.width-27 {
  width: 27%;
}

.width-28 {
  width: 28%;
}

.width-29 {
  width: 29%;
}

.width-30 {
  width: 30%;
}

.width-31 {
  width: 31%;
}

.width-32 {
  width: 32%;
}

.width-33 {
  width: 33%;
}

.width-33033 {
  width: 33.33%;
}

.width-34 {
  width: 34%;
}

.width-35 {
  width: 35%;
}

.width-36 {
  width: 36%;
}

.width-37 {
  width: 37%;
}

.width-38 {
  width: 38%;
}

.width-39 {
  width: 39%;
}

.width-40 {
  width: 40%;
}

.width-41 {
  width: 41%;
}

.width-42 {
  width: 42%;
}

.width-43 {
  width: 43%;
}

.width-44 {
  width: 44%;
}

.width-45 {
  width: 45%;
}

.width-46 {
  width: 46%;
}

.width-47 {
  width: 47%;
}

.width-48 {
  width: 48%;
}

.width-49 {
  width: 49%;
}

.width-50 {
  width: 50%;
}

.width-51 {
  width: 51%;
}

.width-52 {
  width: 52%;
}

.width-53 {
  width: 53%;
}

.width-54 {
  width: 54%;
}

.width-55 {
  width: 55%;
}

.width-56 {
  width: 56%;
}

.width-57 {
  width: 57%;
}

.width-58 {
  width: 58%;
}

.width-59 {
  width: 59%;
}

.width-60 {
  width: 60%;
}

.width-61 {
  width: 61%;
}

.width-62 {
  width: 62%;
}

.width-63 {
  width: 63%;
}

.width-64 {
  width: 64%;
}

.width-65 {
  width: 65%;
}

.width-66 {
  width: 66%;
}

.width-67 {
  width: 67%;
}

.width-68 {
  width: 68%;
}

.width-69 {
  width: 69%;
}

.width-70 {
  width: 70%;
}

.width-71 {
  width: 71%;
}

.width-72 {
  width: 72%;
}

.width-73 {
  width: 73%;
}

.width-74 {
  width: 74%;
}

.width-75 {
  width: 75%;
}

.width-76 {
  width: 76%;
}

.width-77 {
  width: 77%;
}

.width-78 {
  width: 78%;
}

.width-79 {
  width: 79%;
}

.width-80 {
  width: 80%;
}

.width-81 {
  width: 81%;
}

.width-82 {
  width: 82%;
}

.width-83 {
  width: 83%;
}

.width-84 {
  width: 84%;
}

.width-85 {
  width: 85%;
}

.width-86 {
  width: 86%;
}

.width-87 {
  width: 87%;
}

.width-88 {
  width: 88%;
}

.width-89 {
  width: 89%;
}

.width-90 {
  width: 90%;
}

.width-91 {
  width: 91%;
}

.width-92 {
  width: 92%;
}

.width-93 {
  width: 93%;
}

.width-94 {
  width: 94%;
}

.width-95 {
  width: 95%;
}

.width-96 {
  width: 96%;
}

.width-97 {
  width: 97%;
}

.width-98 {
  width: 98%;
}

.width-99 {
  width: 99%;
}

.width-100 {
  width: 100%;
}

.height-1 {
  height: 1%;
}

.height-2 {
  height: 2%;
}

.height-3 {
  height: 3%;
}

.height-4 {
  height: 4%;
}

.height-5 {
  height: 5%;
}

.height-6 {
  height: 6%;
}

.height-7 {
  height: 7%;
}

.height-8 {
  height: 8%;
}

.height-9 {
  height: 9%;
}

.height-10 {
  height: 10%;
}

.height-11 {
  height: 11%;
}

.height-12 {
  height: 12%;
}

.height-13 {
  height: 13%;
}

.height-14 {
  height: 14%;
}

.height-15 {
  height: 15%;
}

.height-16 {
  height: 16%;
}

.height-17 {
  height: 17%;
}

.height-18 {
  height: 18%;
}

.height-19 {
  height: 19%;
}

.height-20 {
  height: 20%;
}

.height-21 {
  height: 21%;
}

.height-22 {
  height: 22%;
}

.height-23 {
  height: 23%;
}

.height-24 {
  height: 24%;
}

.height-25 {
  height: 25%;
}

.height-26 {
  height: 26%;
}

.height-27 {
  height: 27%;
}

.height-28 {
  height: 28%;
}

.height-29 {
  height: 29%;
}

.height-30 {
  height: 30%;
}

.height-31 {
  height: 31%;
}

.height-32 {
  height: 32%;
}

.height-33 {
  height: 33%;
}

.height-33033 {
  height: 33.33%;
}

.height-34 {
  height: 34%;
}

.height-35 {
  height: 35%;
}

.height-36 {
  height: 36%;
}

.height-37 {
  height: 37%;
}

.height-38 {
  height: 38%;
}

.height-39 {
  height: 39%;
}

.height-40 {
  height: 40%;
}

.height-41 {
  height: 41%;
}

.height-42 {
  height: 42%;
}

.height-43 {
  height: 43%;
}

.height-44 {
  height: 44%;
}

.height-45 {
  height: 45%;
}

.height-46 {
  height: 46%;
}

.height-47 {
  height: 47%;
}

.height-48 {
  height: 48%;
}

.height-49 {
  height: 49%;
}

.height-50 {
  height: 50%;
}

.height-51 {
  height: 51%;
}

.height-52 {
  height: 52%;
}

.height-53 {
  height: 53%;
}

.height-54 {
  height: 54%;
}

.height-55 {
  height: 55%;
}

.height-56 {
  height: 56%;
}

.height-57 {
  height: 57%;
}

.height-58 {
  height: 58%;
}

.height-59 {
  height: 59%;
}

.height-60 {
  height: 60%;
}

.height-61 {
  height: 61%;
}

.height-62 {
  height: 62%;
}

.height-63 {
  height: 63%;
}

.height-64 {
  height: 64%;
}

.height-65 {
  height: 65%;
}

.height-66 {
  height: 66%;
}

.height-67 {
  height: 67%;
}

.height-68 {
  height: 68%;
}

.height-69 {
  height: 69%;
}

.height-70 {
  height: 70%;
}

.height-71 {
  height: 71%;
}

.height-72 {
  height: 72%;
}

.height-73 {
  height: 73%;
}

.height-74 {
  height: 74%;
}

.height-75 {
  height: 75%;
}

.height-76 {
  height: 76%;
}

.height-77 {
  height: 77%;
}

.height-78 {
  height: 78%;
}

.height-79 {
  height: 79%;
}

.height-80 {
  height: 80%;
}

.height-81 {
  height: 81%;
}

.height-82 {
  height: 82%;
}

.height-83 {
  height: 83%;
}

.height-84 {
  height: 84%;
}

.height-85 {
  height: 85%;
}

.height-86 {
  height: 86%;
}

.height-87 {
  height: 87%;
}

.height-88 {
  height: 88%;
}

.height-89 {
  height: 89%;
}

.height-90 {
  height: 90%;
}

.height-91 {
  height: 91%;
}

.height-92 {
  height: 92%;
}

.height-93 {
  height: 93%;
}

.height-94 {
  height: 94%;
}

.height-95 {
  height: 95%;
}

.height-96 {
  height: 96%;
}

.height-97 {
  height: 97%;
}

.height-98 {
  height: 98%;
}

.height-99 {
  height: 99%;
}

.height-100 {
  height: 100%;
}

.button {
  font-family: "IRANYekan", sans-serif;
  border: none;
  border-radius: 7px;
  color: #030303;
}


.hide-scrollBar::-webkit-scrollbar {
  display: none;
}

.hide-scrollBar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollView::-webkit-scrollbar {
  display: none;
}

.scrollView {
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin: 0 !important;
}

.thumb-vertical {
  background-color: #0707073b;
  -webkit-transition: background-color 0.4s;
  -o-transition: background-color 0.4s;
  transition: background-color 0.4s;
  border-radius: 50vw;
}


.hover-scale-01:hover {
  font-size: 1.1rem;
}

.hover-text:hover {
  color: #007bff;
}

.hover-underline:hover {
  text-decoration: underline;
}


.col-04 {
  -webkit-box-flex: 0.04;
  -ms-flex: 0.04;
  flex: 0.04;
}

.col-03 {
  -webkit-box-flex: 0.03;
  -ms-flex: 0.03;
  flex: 0.03;
}

.col-05 {
  -webkit-box-flex: 0.05;
  -ms-flex: 0.05;
  flex: 0.05;
}

.col-07 {
  -webkit-box-flex: 0.07;
  -ms-flex: 0.07;
  flex: 0.07;
}

.col-30 {
  -webkit-box-flex: 0.3;
  -ms-flex: 0.3;
  flex: 0.3;
}

.col-33 {
  -webkit-box-flex: 0.33;
  -ms-flex: 0.33;
  flex: 0.33;
}

.col-34 {
  -webkit-box-flex: 0.34;
  -ms-flex: 0.34;
  flex: 0.34;
}

.col-80 {
  -webkit-box-flex: 0.8;
  -ms-flex: 0.8;
  flex: 0.8;
}

.col-88 {
  -webkit-box-flex: 0.88;
  -ms-flex: 0.88;
  flex: 0.88;
}

.col-89 {
  -webkit-box-flex: 0.89;
  -ms-flex: 0.89;
  flex: 0.89;
}

.col-35 {
  -webkit-box-flex: 0.35;
  -ms-flex: 0.35;
  flex: 0.35;
}

.col-38 {
  -webkit-box-flex: 0.38;
  -ms-flex: 0.38;
  flex: 0.38;
}

.col-25 {
  -webkit-box-flex: 0.25;
  -ms-flex: 0.25;
  flex: 0.25;
}

.col-29 {
  -webkit-box-flex: 0.29;
  -ms-flex: 0.29;
  flex: 0.29;
}

.col-20 {
  -webkit-box-flex: 0.2;
  -ms-flex: 0.2;
  flex: 0.2;
}

.col-10 {
  -webkit-box-flex: 0.1;
  -ms-flex: 0.1;
  flex: 0.1;
}

.col-06 {
  -webkit-box-flex: 0.06;
  -ms-flex: 0.06;
  flex: 0.06;
}

.col-08 {
  -webkit-box-flex: 0.08;
  -ms-flex: 0.08;
  flex: 0.08;
}

.col-09 {
  -webkit-box-flex: 0.09;
  -ms-flex: 0.09;
  flex: 0.09;
}

.col-15 {
  -webkit-box-flex: 0.15;
  -ms-flex: 0.15;
  flex: 0.15;
}

.col-18 {
  -webkit-box-flex: 0.18;
  -ms-flex: 0.18;
  flex: 0.18;
}

.col-23 {
  -webkit-box-flex: 0.23;
  -ms-flex: 0.23;
  flex: 0.23;
}

.col-28 {
  -webkit-box-flex: 0.28;
  -ms-flex: 0.28;
  flex: 0.28;
}

.col-21 {
  -webkit-box-flex: 0.21;
  -ms-flex: 0.21;
  flex: 0.21;
}

.col-75 {
  -webkit-box-flex: 0.75;
  -ms-flex: 0.75;
  flex: 0.75;
}

.col-65 {
  -webkit-box-flex: 0.65;
  -ms-flex: 0.65;
  flex: 0.65;
}

.col-69 {
  -webkit-box-flex: 0.69;
  -ms-flex: 0.69;
  flex: 0.69;
}

.col-70 {
  -webkit-box-flex: 0.7;
  -ms-flex: 0.7;
  flex: 0.7;
}

.col-44 {
  -webkit-box-flex: 0.44;
  -ms-flex: 0.44;
  flex: 0.44;
}

.col-45 {
  -webkit-box-flex: 0.45;
  -ms-flex: 0.45;
  flex: 0.45;
}

.col-41 {
  -webkit-box-flex: 0.41;
  -ms-flex: 0.41;
  flex: 0.41;
}

.col-50 {
  -webkit-box-flex: 0.5;
  -ms-flex: 0.5;
  flex: 0.5;
}

.col-40 {
  -webkit-box-flex: 0.4;
  -ms-flex: 0.4;
  flex: 0.4;
}

.col-60 {
  -webkit-box-flex: 0.6;
  -ms-flex: 0.6;
  flex: 0.6;
}

.col-55 {

  -webkit-box-flex: 0.55;
  -ms-flex: 0.55;
  flex: 0.55;
}

.col-58 {
  -webkit-box-flex: 0.58;
  -ms-flex: 0.58;
  flex: 0.58;
}

.col-49 {
  -webkit-box-flex: 0.49;
  -ms-flex: 0.49;
  flex: 0.49;
}

.col-48 {
  -webkit-box-flex: 0.48;
  -ms-flex: 0.48;
  flex: 0.48;
}

.col-46 {
  -webkit-box-flex: 0.46;
  -ms-flex: 0.46;
  flex: 0.46;
}

.col-98 {
  -webkit-box-flex: 0.98;
  -ms-flex: 0.98;
  flex: 0.98;
}

.col-90 {
  -webkit-box-flex: 0.90;
  -ms-flex: 0.90;
  flex: 0.90;
}

.col-92 {
  -webkit-box-flex: 0.92;
  -ms-flex: 0.92;
  flex: 0.92;
}

.col-93 {
  -webkit-box-flex: 0.93;
  -ms-flex: 0.93;
  flex: 0.93;
}

.col-94 {
  -webkit-box-flex: 0.94;
  -ms-flex: 0.94;
  flex: 0.94;
}

.col-95 {
  -webkit-box-flex: 0.95;
  -ms-flex: 0.95;
  flex: 0.95;
}

.col-96 {
  -webkit-box-flex: 0.96;
  -ms-flex: 0.96;
  flex: 0.96;
}

.col-99 {
  -webkit-box-flex: 0.99;
  -ms-flex: 0.99;
  flex: 0.99;
}

.col-100 {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.flashit {
  -webkit-animation: flash linear 1s infinite;
  animation: flash linear 1s infinite;
}

@-webkit-keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
.flashit-3 {
  -webkit-animation: flash linear 1s 3;
  animation: flash linear 1s 3;
}

@-webkit-keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}


table th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  font-weight: normal;
  background-color: #f9f9f9;
  line-height: 3.7vh;
}

.th-border-y th {
  border-top: 0.1vh solid #d3cdcd;
  border-bottom: 0.1vh solid #d3cdcd;
}

.success-filter {
  filter: invert(59%) sepia(18%) saturate(1702%) hue-rotate(128deg) brightness(103%) contrast(93%);
}

.green-filter {
  filter: invert(45%) sepia(96%) saturate(1728%) hue-rotate(128deg) brightness(93%) contrast(81%);
}

.danger-filter {
  filter: invert(47%) sepia(53%) saturate(4759%) hue-rotate(327deg) brightness(102%) contrast(93%);
}

.default-filter {
  filter: invert(89%) sepia(69%) saturate(1%) hue-rotate(114deg) brightness(110%) contrast(101%);
}

.gray-filter {
  filter: invert(45%) sepia(10%) saturate(0%) hue-rotate(260deg) brightness(85%) contrast(81%);
}
.blue-filter {
  filter: invert(27%) sepia(51%) saturate(4312%) hue-rotate(225deg) brightness(90%) contrast(87%);
}
.text-filter {
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(159deg) brightness(97%) contrast(103%);
}
.user-select-none {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.header {
  height: 8vh;
  background-color: #e7e7e7;
  border-radius: 8px 8px 0 0;
}

.text-area {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 7px;
  text-align: left;
  font-family: iranyekan, serif;
  background: none;
  color: #242633fa !important;
  border: 0.18vh solid #e7e7e7;
  padding: 11px;
}


.floating {
  -webkit-animation: floating 5s ease-in-out infinite;
  -moz-animation: floating 5s ease-in-out infinite;
  animation: floating 5s ease-in-out infinite;
}

@keyframes floating {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    -moz-transform: translateY(0) rotate(.2deg);
  }
  50% {
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px);
    -moz-transform: translateY(10px) rotate(.2deg);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    -moz-transform: translateY(0) rotate(.2deg);
  }
}


.list {
  background-color: #D9D9D9;
}


.blur-2 {
  filter: blur(2px);
}