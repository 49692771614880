.container {


}
.image {
    width: 85%;

}

.thisButton {
    background-color: #2096F3;
    border-radius: 8px;
    direction: ltr;
    text-align: center;
    font-size: 0.8rem;
    padding: 1.3vh 0vw;
    color: #fff;
    width: 30%;
    border: 1px solid #2096F3;
}


.prev {
    background-color: inherit;
    color: inherit;
}

.next {

}


.check {
    filter: invert(59%) sepia(18%) saturate(1702%) hue-rotate(128deg) brightness(103%) contrast(93%);
}

.close {
    filter: invert(47%) sepia(53%) saturate(4759%) hue-rotate(327deg) brightness(102%) contrast(93%);
}

.icon {
    width: 5vw;
}

@media (max-width: 480px) {

    .icon{
        width: 15vw;
    }

}
