.image {
    width: 55%;
}

.video{
    /*transform: scaleX(-1);*/
}

.thisButton {
    background-color: #2096F3;
    border-radius: 8px;
    direction: ltr;
    text-align: center;
    font-size: 0.8rem;
    padding: 1.3vh 0vw;
    color: #fff;
    width: 30%;
    border: 1px solid #2096F3;
}


.prev {
    background-color: inherit;
    color: inherit;
}

.next {

}
