.thisButton {
    background-color: #2096F3;
    border-radius: 8px;
    direction: ltr;
    text-align: center;
    font-size: 0.8rem;
    padding: 1.3vh 0vw;
    color: #fff;
    width: 25%;
}

.thisButton:disabled,.button[disabled] {
    background: #e7e7e7;
    color: #444444;
    cursor: not-allowed;
}

.captcha{
    width: 32.5%;
}

