.thisButton {
    background-color: #316bff;
    color: #fff;
    border-radius: 8px;
    direction: ltr;
    text-align: center;
    font-size: 0.8rem;
    padding: 1.3vh 1.5vw;
}

@media (max-width: 480px) {
    .thisButton {
        padding: 1.3vh 3vw;
    }
}


.thisButton:hover {

}