.logo {
    width: 3vw;
}

@media (max-width: 480px) {
    .logo {
        width: 9vw;
    }
}

.container {
    border-bottom: 2px dotted #d8d9dc;
}