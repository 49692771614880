.container{
    top: 0;
    bottom: 0;
    position: absolute;
    left: 0;
    right: 0;
}

.image {
    width: 17%;
}

@media (max-width: 480px) {
    .image {
        width: 21%;
    }

}