.container {
    top: 8%;
    bottom: 8%;
    left: 35%;
    width: 30%;
    position: absolute;
    border-radius: 8px;


}

@media (max-width: 480px) {
    .container {
        top: 5%;
        bottom: 5%;
        left: 7.5%;
        width: 85%;
        position: absolute;
        border-radius: 8px;
    }
}