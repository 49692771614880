.container {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    overflow: hidden;
    position: relative;
}

.header {
    /*background-color: #D3D3D3B0;*//*background-color: #D3D3D3B0;*/
    height: 100%;
    z-index: 10;
    top: 0;
    color: #ecececc7;
}

.title{
    /*background-color: #242121cf;*/
    background-color: #EFEFEFB6;
    color: #000;
    /*border-radius: 50px;*/
    line-height: 3.5vh;
}

.check {
    filter: invert(59%) sepia(18%) saturate(1702%) hue-rotate(128deg) brightness(103%) contrast(93%);
}

.close {
    filter: invert(47%) sepia(53%) saturate(4759%) hue-rotate(327deg) brightness(102%) contrast(93%);
}

.icon {
    width: 3.2vw;
}

@media (max-width: 480px) {

    .icon{
        width: 10vw;
    }

}

.wrapper {
    top: 0;
    bottom: 0;
    background-color: #030303BF;
}

.frame {
    top: 0;
    bottom: 0;
    background-repeat: repeat;
    background-position: center center;
    background-size: cover;
}

.thisButton {
    background-color: #2096F3;
    border-radius: 8px;
    direction: ltr;
    text-align: center;
    font-size: 0.8rem;
    padding: 1.3vh 0vw;
    color: #fff;
    width: 30%;
    bottom: 5%;
    z-index: 11;
}


.fullWrapper {
    top: 0;
    bottom: 0;
    background-color: #030303BF;
    color: #dddddd;
}