.logo {
    width: 6vw;
}

.thisButton {
    background-color: #2096F3;
    border-radius: 8px;
    direction: ltr;
    text-align: center;
    font-size: 0.8rem;
    padding: 1.3vh 0vw;
    color: #fff;
    width: 25%;
}

.thisButton:disabled,.button[disabled] {
    background: #e7e7e7;
    color: #444444;
    cursor: not-allowed;
}

.captcha{
    width: 32.5%;
}

.otpInputContainer {
    margin: 2vh auto 0;
}

.otpInput {
    width: 3vw !important;
    height: 8vh;
    font-size: 1.3rem;
    font-family: iranyekan, serif;
    border-radius: 9px;
    border: 1px solid #3A3838C1;
}

@media (max-width: 480px) {
    .otpInput {
        width: 11vw !important;
        height: 6.5vh;
    }
}


.otpInputContainer span {
    margin: 0 0.3vw !important;
}